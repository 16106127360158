var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forget-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "login-form",
          attrs: {
            model: _vm.form,
            autocomplete: "on",
            "label-position": "left",
            "label-width": "100px",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("login.resetPass")) + "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "user_name", label: _vm.$t("login.username") } },
            [
              _c("el-input", {
                staticStyle: { display: "inline-block", width: "380px" },
                model: {
                  value: _vm.form.user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_name", $$v)
                  },
                  expression: "form.user_name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "email", label: _vm.$t("login.email") } },
            [
              _c("el-input", {
                staticStyle: { display: "inline-block", width: "380px" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: {
                width: "100%",
                "margin-top": "30px",
                "margin-bottom": "30px",
              },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.confirm($event)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("button.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }