//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validUsername } from '@/utils/validate';
import LangSelect from '@/components/LangSelect';
import request from '@/utils/request';
export default {
  name: 'ResetPass',
  components: {
    LangSelect: LangSelect
  },
  data: function data() {
    return {
      form: {
        user_name: null,
        email: null
      },
      loading: false
    };
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    confirm: function confirm() {
      var _this = this;
      var user_name = this.form.user_name;
      var email = this.form.email;
      if (isNull(user_name)) {
        this.$message.error('请输入用户名');
        return;
      }
      if (isNull(email)) {
        this.$message.error('请输入邮箱');
        return;
      }
      if (!/.+@.+\..+/img.test(email)) {
        this.$message.error('输入邮箱格式不正确');
        return;
      }
      request({
        url: 'user/forget-password',
        method: 'post',
        data: {
          user_name: user_name,
          email: email
        }
      }).then(function (response) {
        if (response.data.code === '0') {
          _this.$message.success(_this.$t(response.data.message));
        } else {
          _this.$message.error(response.data.message);
        }
      });
    }
  }
};